import React from "react"
import { RichText } from "jam-cms"
import { Box } from "theme-ui"

const Text = (props) => {
  const { text } = props

  return (
    <Box sx={{ ".prose": { maxWidth: "100%", textAlign: "start" } }}>
      <div className="prose text-start pb-16">
        <RichText>{text}</RichText>
      </div>
    </Box>
  )
}

export default Text
