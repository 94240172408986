import buttonFields from "../button/config"

const config = {
  id: "contactus",
  label: "Contact Us",
  fields: [
    {
      id: "flex",
      type: "flexible_content",
      label: "Form",
      items: [
        {
          id: "form",
          type: "layout",
          label: "Form",
          fields: [
            {
              id: "formid",
              type: "number",
              label: "Form ID",
              defaultValue: 1 // optional
            }
          ]
        }
      ]
    },
    {
      id: "link",
      type: "link",
      label: "Map Link"
    },
    {
      id: "headline",
      type: "text",
      label: "Headline",
      defaultValue: "Feedback"
    },

    {
      id: "text",
      type: "text",
      label: "Text",
      defaultValue: "This is some text for text value"
    },
    {
      id: "phone",
      type: "text",
      label: "Phone",
      defaultValue: "123-456-7890"
    },
    {
      id: "email",
      type: "text",
      label: "Email",
      defaultValue: "robinzimmer1989@web.de"
    },
    {
      id: "address",
      type: "text",
      label: "Address",
      defaultValue: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter"
    },

    {
      id: "subline",
      type: "text",
      label: "Subline",
      defaultValue: "Viral pour-over ugh narwhal flexitarian raclette woke "
    }
  ]
}

export default config
