import buttonFields from "../button/config"

const config = {
  id: "reviews",
  label: "Reviews",
  fields: [
    {
      id: "items",
      type: "repeater",
      label: "Items",
      items: [
        {
          id: "person",
          type: "text",
          label: "Person"
        },
        {
          id: "position",
          type: "text",
          label: "Position"
        },
        {
          id: "star",
          type: "number",
          label: "Star"
        },

        {
          id: "text",
          type: "text",
          label: "Text",
          rows: 3
        }
      ]
    },
    {
      id: "buttons",
      type: "repeater",
      label: "Button",
      items: buttonFields
    }
  ]
}

export default config
