import React from "react"

// import app components
import Text from "./Text"
import TextImage from "./TextImage"
import Gallery from "./Gallery"
import Embed from "./Embed"
import Quote from "./Quote"

const TextEditor = (props) => {
  const { flex } = props

  const getFlexElement = (block) => {
    const id = block?.id || block?.fieldGroupName?.split("_").pop().toLowerCase()

    const layouts = {
      text: <Text {...block} />,
      textimage: <TextImage {...block} />,
      gallery: <Gallery {...block} />,
      embed: <Embed {...block} />,
      quote: <Quote {...block} />
    }

    return layouts[id]
  }

  return (
    <div className={`mx-auto w-5/6 m-0 lg:w-2/3`}>
      {flex && flex.map((block, i) => <div key={i}>{getFlexElement(block)}</div>)}
    </div>
  )
}

export default TextEditor
