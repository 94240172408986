import buttonFields from "../button/config"

const config = {
  id: "postcards",
  label: "Postcards",
  fields: [
    {
      id: "bgcolor",
      type: "radio",
      label: "Background Color",
      defaultValue: "background-one",
      options: [
        {
          name: "Background 1",
          value: "background-one"
        },
        {
          name: "Background 2",
          value: "background-two"
        }
      ]
    },
    {
      id: "items",
      type: "repeater",
      label: "Items",
      items: [
        {
          id: "headline",
          type: "text",
          label: "Headline"
        },
        {
          id: "image",
          type: "image",
          label: "Add Image"
        },
        {
          id: "text",
          type: "text",
          label: "Text",
          rows: 3
        },
        {
          id: "buttons",
          type: "repeater",
          label: "Button",
          items: buttonFields
        }
      ]
    }
  ]
}

export default config
