// import fleixble content blocks
import hero from "../../../../components/hero/config"
import cards from "../../../../components/cards/config"
import textimage from "../../../../components/textImage/config"
import texteditor from "../../../../components/textEditor/config"
import posts from "../../../../components/posts/config"
import postcards from "../../../../components/postcards/config"
import quote from "../../../../components/quote/config"
import angledimage from "../../../../components/angledImage/config"
import contactus from "../../../../components/contactUs/config"
import form from "../../../../components/form/config"
import cta from "../../../../components/cta/config"
import downloads from "../../../../components/downloads/config"
import reviews from "../../../../components/reviews/config"
import content from "../../../../components/content/config"
import resources from "../../../../components/resources/config"

const config = {
  id: "default",
  postTypeID: "page",
  label: "Default Page",
  fields: [
    {
      id: "header",
      global: true
    },
    {
      id: "blocks",
      label: "Content",
      type: "group",
      fields: [
        {
          id: "flex",
          label: "Blocks",
          type: "flexible_content",
          items: Object.values({
            hero,
            cards,
            textimage,
            texteditor,
            posts,
            postcards,
            quote,
            angledimage,
            contactus,
            form,
            cta,
            downloads,
            reviews,
            content,
            resources
          })
        }
      ]
    },
    {
      id: "footer",
      global: true
    }
  ]
}

export default config
