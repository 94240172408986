import React from "react"
import { GatsbyImage } from "jam-cms"
import Parser from "html-react-parser"

// import app components
import Button from "../button/Button"

const Resources = (props) => {
  const { items } = props

  return (
    <section className="text-gray-600 body-font pb-16">
      {items &&
        items.map((o, idx) => (
          <div className="container px-5 py-10 mx-auto">
            <div className="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
              {o.headline && (
                <h1 className="flex-grow mb-6 md:mb-0 sm:pr-16 text-2xl font-medium title-font text-gray-900">
                  {Parser(o.headline)}
                </h1>
              )}

              {o.buttons && o.buttons.length > 0 && (
                <>
                  {o.buttons.map((obj, i) => (
                    <Button
                      className="flex-shrink-0 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-10 sm:mt-0"
                      key={i}
                      {...obj.button}
                      variant={obj.variant}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        ))}
    </section>
  )
}

export default Resources
