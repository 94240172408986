import React from "react"
import { Link } from "gatsby"
import Parser from "html-react-parser"
import { GatsbyImage } from "jam-cms"

// import app components
import Github from "../../icons/github.svg"

const Footer = (props) => {
  const { text, facebook, instagram, twitter, linkedin, image } = props

  return (
    <footer className="text-white body-font bg-blue-gray-950">
      <div className=" px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
        <a
          href="/"
          rel="noopener noreferrer"
          className=" title-font font-medium  md:justify-start justify-center text-gray-900"
        >
          {image && (
            <>
              {image?.svg ? (
                Parser(image.svg)
              ) : (
                <GatsbyImage
                  image={image}
                  alt={image?.altText}
                  objectFit="contain"
                  objectPosition="0"
                  className="w-22 h-10 text-primary-contrast "
                />
              )}
            </>
          )}
        </a>

        <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start ">
          {facebook && (
            <a className="text-gray-500" href={facebook} target="_blank" rel="noopener noreferrer">
              <svg
                fill="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-5 h-5 "
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
          )}
          {twitter && (
            <a className="ml-3 text-gray-500" href={twitter} target="_blank" rel="noopener noreferrer">
              <svg
                fill="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
          )}
          {instagram && (
            <a className="ml-3 text-gray-500" href={instagram} target="_blank" rel="noopener noreferrer">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>
          )}

          {linkedin && (
            <a className="ml-3 text-gray-500" href={linkedin} target="_blank" rel="noopener noreferrer">
              <svg
                fill="currentColor"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="0"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                ></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a>
          )}
        </span>
      </div>
    </footer>
  )
}

export default Footer
