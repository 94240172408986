import React from "react"
import { GatsbyImage } from "jam-cms"
import Parser from "html-react-parser"

// import app components
import Button from "../button/Button"
import { rangeSpec } from "react-jsonschema-form/lib/utils"

const Reviews = (props) => {
  const { items, buttons } = props

  return (
    <section className="text-gray-600 body-font pb-16">
      <div className="container px-5 py-10 mx-auto">
        <div className="flex flex-wrap flex-col sm:flex-row  -m-4">
          {items &&
            items.map((o, idx) => (
              <div
                className={`w-full md:w-1/2 lg:${
                  items.length > 2 ? "w-1/3" : items.length > 1 ? "w-1/2" : "w-full"
                } lg:mb-0 mb-6 p-4`}
              >
                <div className="h-full text-center" key={idx}>
                  {o.star &&
                    Number.isInteger(o.star) &&
                    [...Array(o.star)].map((s, idx) => (
                      <svg
                        className="  mb-8 object-cover object-center rounded-full inline-block  "
                        width="31"
                        height="30"
                        viewBox="0 0 31 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M31.0002 11.4322H19.4116L15.823 0.123779L12.2344 11.4322H0.645874L10.0173 18.4472L6.45158 29.7557L15.823 22.7642L25.1944 29.7557L21.6287 18.4472L31.0002 11.4322Z"
                          fill="#F5B306"
                        />
                      </svg>
                    ))}
                  {o.text && <p className="leading-relaxed">{Parser(o.text)}</p>}
                  <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
                  {o.person && (
                    <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">{Parser(o.person)}</h2>
                  )}
                  {o.position && <p className="text-gray-500">{Parser(o.position)}</p>}
                </div>
              </div>
            ))}
          <div className="flex justify-center pt-16 w-full">
            {buttons && buttons.length > 0 && (
              <>
                {buttons.map((obj, i) => (
                  <Button
                    className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-lg text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                    key={i}
                    {...obj.button}
                    variant={obj.variant}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Reviews
