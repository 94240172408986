import React from "react"
import { GatsbyImage, RichText } from "jam-cms"
import Parser from "html-react-parser"
import { Box } from "theme-ui"

// import app components
import Button from "../button/Button"

const Hero = (props) => {
  const { image, headline, subline, buttons, alignment, bgcolor } = props

  return (
    <section className={`bg-${(bgcolor && bgcolor) || "gray-600"} text-primary-contrast body-font pb-16`}>
      {console.log(bgcolor)}
      <div className="w-full  mx-auto flex items-center justify-center flex-col ">
        <Box sx={{ width: "100%", height: "100%", mb: ["10px", "30px"] }}>
          {image ? (
            <GatsbyImage image={image} alt={image.altText} className="object-cover h-full w-full mb-10" />
          ) : (
            <img className="object-fit w-full mb-10" alt="hero" src="https://dummyimage.com/1620x550" />
          )}
        </Box>
        <div className={`${alignment === "Center" ? "text-center" : "text-start"} lg:w-2/3  w-5/6`}>
          {headline && (
            <h1 className="font-black sm:text-4xl text-3xl pb-4 text-primary-contrast">{Parser(headline)}</h1>
          )}
          {subline && (
            <Box
              sx={{
                ".prose": {
                  my: "30px",
                  maxWidth: "100%",
                  textAlign: "start",
                  color: "#ffffff",
                  p: { color: "#ffffff" },
                  "ol > li::before": { color: "#ffffff" },
                  "ul > li::before": { bg: "#ffffff" },
                  h1: { color: "#060606", fontWeight: 900 },
                  h2: { color: "#ffffff", fontWeight: 900, fontSize: "2.25em" },
                  h3: { color: "#ffffff", fontWeight: 700 }
                }
              }}
            >
              <div className="prose text-start">
                <RichText>{subline}</RichText>
              </div>
            </Box>
          )}

          <div className={`flex justify-center ${buttons && buttons.length > 0 ? "py-5" : null} w-full`}>
            {buttons && buttons.length > 0 && (
              <>
                {buttons.map((obj, i) => (
                  <Button
                    className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-lg text-primary-contrast transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                    key={i}
                    {...obj.button}
                    variant={obj.variant}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
