import React, { useState } from "react"

import FormContainer from "../FormContainer"

const Form = (props) => {
  return (
    <>
      {props.hasStyle ? (
        <FormContainer {...props} />
      ) : (
        <section className="text-gray-600 body-font pb-16">
          <div className="container px-5 pt-10 mx-auto">
            <div className="flex lg:w-2/3 w-full sm:flex-row flex-col mx-auto px-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end justify-center">
              <FormContainer {...props} />
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default Form
