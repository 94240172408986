import React from "react"
import { GatsbyImage, RichText } from "jam-cms"
import Parser from "html-react-parser"

// import app components
import Button from "../button/Button"

const Postcards = (props) => {
  const { items, bgcolor } = props
  return (
    <section className={`bg-${(bgcolor && bgcolor) || "gray-600"} text-primary-contrast body-font pb-16`}>
      <div className="container px-5 py-10 mx-auto">
        <div className="flex flex-wrap -mx-4 -mb-10 text-center">
          {items &&
            items.map((o, idx) => (
              <div className="sm:w-1/2 mb-10 px-4">
                <div className=" h-80 overflow-hidden">
                  {o.image ? (
                    <GatsbyImage
                      image={o.image}
                      alt={o.image.altText}
                      className="object-cover object-center h-full w-full"
                    />
                  ) : (
                    <img
                      alt="content"
                      className="object-cover object-center h-full w-full"
                      src="https://dummyimage.com/1201x501"
                    />
                  )}
                </div>
                {o.headline && <h2 className="text-2xl font-black	 text-white mt-6 mb-3">{Parser(o.headline)}</h2>}

                {o.text && <p className="leading-relaxed text-base">{Parser(o.text)}</p>}

                <div
                  className={`flex items-center ${
                    o.buttons && o.buttons.length > 0 ? "pt-5" : null
                  } justify-center align-middle`}
                >
                  {o.buttons && o.buttons.length > 0 && (
                    <>
                      {o.buttons.map((obj, i) => (
                        <Button
                          className="flex mx-auto mt-6 text-primary-contrast bg-primary  py-2 px-5 focus:outline-none hover:bg-indigo-600 rounded"
                          key={i}
                          {...obj.button}
                          variant={obj.variant}
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Postcards
