import React from "react"
import { RichText } from "jam-cms"
import Parser from "html-react-parser"
import moment from "moment"

// import app components
import Button from "../button/Button"
import Form from "../form/Form"

const Downloads = (props) => {
  const { items } = props

  return (
    <section className="text-gray-600 body-font overflow-hidden">
      <div className="container px-5 py-10 mx-auto">
        <div className="-my-8 divide-y-2 divide-gray-100">
          {items &&
            items.map((o, idx) => (
              <div className="grid mx-auto w-5/6 py-4 lg:w-2/3 gap-0 row-gap-5 md:row-gap-8 sm:mx-auto  lg:grid-cols-2 xl:grid-cols-3">
                <div className="lg:row-start-1 lg:col-start-1 md:w-64 lg:mb-0 mb-6 flex-shrink-0  flex flex-col break-all">
                  {o.category && <span className="font-semibold title-font text-gray-700">{Parser(o.category)}</span>}
                  {o.date && <span className="mt-1 text-gray-500 text-sm">{moment(o.date).format("l")}</span>}
                </div>
                <div className="lg:row-start-1 lg:col-start-2 col-span-2 break-all">
                  {o.headline && (
                    <h2 className="text-2xl font-medium text-gray-900 title-font mb-2">{Parser(o.headline)}</h2>
                  )}
                  {o.text && <p className="leading-relaxed">{Parser(o.text)}</p>}
                  {o.link && (
                    <a href={o.link.url} className="text-indigo-500 inline-flex items-center mt-4">
                      {Parser(o.link.title)}
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Downloads
