import React from "react"
import { GatsbyImage, RichText } from "jam-cms"
import Parser from "html-react-parser"
import { Box } from "theme-ui"
import styled from "@emotion/styled"

// import app components
import Button from "../button/Button"

const TextImage = (props) => {
  const { image, headline, text, buttons, alignment } = props

  return (
    <div className="py-8">
      <div
        className={
          "grid mx-auto w-5/6 m-0 lg:w-2/3 gap-0 row-gap-5 md:row-gap-8 sm:mx-auto  lg:grid-cols-2 xl:grid-cols-3"
        }
      >
        <ImageContainer alignment={alignment}>
          {console.log("props", props.alignment === "left")}
          {image && (
            <GatsbyImage
              image={image}
              alt={image.altText}
              style={{ width: "100%", height: "100%" }}
              className="object-cover object-center rounded"
            />
          )}
        </ImageContainer>
        <TextContainer alignment={alignment}>
          <div
          // className={`lg:row-start-1 xl:col-start-${alignment === "left" ? 2 : 1}  lg:col-start-${
          //   alignment === "left" ? 2 : 1
          // } py-8 xl:col-span-2 ${alignment === "left" ? "lg:pl-16" : "lg:pr-16"}`}
          >
            {headline && (
              <h2 className="title-font sm:text-l text-2xl mb-4 font-medium text-gray-900">{Parser(headline)}</h2>
            )}
            {text && (
              <Box
                sx={{
                  ".prose": {
                    maxWidth: "100%",
                    textAlign: "start",
                    color: "rgba(0, 0, 0, 0.85)",
                    "ol > li::before": { color: "rgba(0, 0, 0, 0.85)" },
                    "ul > li::before": { bg: "rgba(0, 0, 0, 0.85)" }
                  }
                }}
              >
                <div className="prose text-start">
                  <RichText>{text}</RichText>
                </div>
              </Box>
            )}

            {buttons && buttons.length > 0 && (
              <div className={`flex justify-left ${buttons && buttons.length > 0 ? "mt-8" : "mt-0"}`}>
                {buttons.map((obj, i) => (
                  <Button
                    className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-lg text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                    key={i}
                    {...obj.button}
                    variant={obj.variant}
                  />
                ))}
              </div>
            )}
          </div>
        </TextContainer>
      </div>
    </div>
  )
}

const ImageContainer = styled.div`
  width: 100%;
  @media (min-width: 1024px) {
    grid-column-start: ${(props) => (props.alignment === "left" ? 1 : 2)};
    grid-row-start: 1;
  }
  @media (min-width: 1280px) {
    grid-column-start: ${(props) => (props.alignment === "left" ? 1 : 3)};
    grid-row-start: 1;
  }
`
const TextContainer = styled.div`
  width: 100%;
  padding-top:40px;
  @media (min-width: 1024px) {
    grid-column-start: ${(props) => (props.alignment === "left" ? 2 : 1)};
    grid-column-end: ${(props) => (props.alignment === "left" ? 3 : 2)};
    padding: 30px;
  @media (min-width: 1280px) {
    grid-column-start: ${(props) => (props.alignment === "left" ? 2 : 1)};
    grid-column-end: ${(props) => (props.alignment === "left" ? 4 : 3)};

    grid-row-start: 1;
  }
`
export default TextImage
