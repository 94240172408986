import React from "react"
import { Link } from "gatsby"

const Button = (props) => {
  const { url, title, variant } = props

  if (!url || !title) {
    return null
  }

  let className = ""

  if (variant === "filled") {
    className =
      "inline-flex items-center justify-center h-12 px-10 py-2 font-medium tracking-wide text-background-one transition duration-200 rounded shadow-md bg-primary-contrast text-primary-contrast hover:opacity-50 focus:shadow-outline focus:outline-none"
  } else if (variant === "text") {
    className =
      "inline-flex items-center font-semibold border-background-one border-2 px-10 py-2 rounded  duration-200 text-secondary-contrast "
  }
  return url.includes("http") ? (
    <a
      href={url}
      className={className}
      children={title}
      title={title}
      aria-label={title}
      target={"_blank"}
      rel="noopener noreferrer"
    />
  ) : (
    <Link to={url} className={className} children={title} />
  )
}

export default Button
