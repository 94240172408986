import React from "react"
import { GatsbyImage, RichText } from "jam-cms"
import Parser from "html-react-parser"
import { Box, Text } from "theme-ui"

// import app components
import Button from "../button/Button"

const Content = (props) => {
  const { image, headline, subline } = props

  return (
    <section className="text-seondary-contrast body-font pb-16">
      <div
        className={`w-full mx-auto flex ${
          subline && subline?.length > 8 ? "pb-10" : "pb-0"
        } items-center justify-center flex-col`}
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          {image ? (
            <GatsbyImage
              image={image}
              alt={image.altText}
              className={`object-cover h-full w-full ${headline.length === 0 ? "mb-0" : "mb-10"}`}
            />
          ) : (
            <img
              className={`object-fit w-full ${headline.length === 0 ? "mb-0" : "mb-10"}`}
              alt="hero"
              src="https://dummyimage.com/1620x140"
            />
          )}
        </Box>

        <div className="text-secondary-contrast text-start lg:w-2/3 w-5/6 m-0">
          {headline && headline.length !== 0 && (
            <h1 className="  font-black sm:text-4xl text-3xl my-6 font-medium text-background-one">
              {Parser(headline)}
            </h1>
          )}
          {subline && subline?.length > 8 && (
            <Box
              sx={{
                ".prose": {
                  maxWidth: "100%",
                  textAlign: "start",
                  color: "rgba(0, 0, 0, 0.85)",
                  "ol > li::before": { color: "rgba(0, 0, 0, 0.85)" },
                  "ul > li::before": { bg: "rgba(0, 0, 0, 0.85)" }
                }
              }}
            >
              <div className="prose">
                <RichText>{subline}</RichText>
              </div>
            </Box>
          )}
        </div>
      </div>
    </section>
  )
}

export default Content
