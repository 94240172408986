const config = {
  id: "cta",
  label: "CTA",
  fields: [
    {
      id: "flex",
      type: "flexible_content",
      label: "Form",
      items: [
        {
          id: "form",
          type: "layout",
          label: "Form",
          fields: [
            {
              id: "formid",
              type: "number",
              label: "Form ID",
              defaultValue: 2 // optional
            }
          ]
        }
      ]
    },
    {
      id: "headline",
      type: "text",
      label: "Headline",
      defaultValue: "Feedback"
    },
    {
      id: "text",
      type: "text",
      label: "Text",
      defaultValue: "This is some text for text value"
    }
  ]
}

export default config
