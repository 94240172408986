import buttonFields from "../button/config"

const config = {
  id: "resources",
  label: "Resources",
  fields: [
    {
      id: "items",
      type: "repeater",
      label: "Items",
      items: [
        {
          id: "headline",
          type: "text",
          label: "Headline",
          defaultValue: "A quote headline"
        },
        {
          id: "buttons",
          type: "repeater",
          label: "Button",
          items: buttonFields
        }
      ]
    }
  ]
}

export default config
