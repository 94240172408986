const config = {
  id: "form",
  label: "Form",
  fields: [
    {
      id: "formid",
      type: "number",
      label: "Form ID",
      defaultValue: 1 // optional
    }
  ]
}

export default config
