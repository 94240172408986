import React from "react"
import { graphql } from "gatsby"

// import app components
import Layout from "../../../../components/Layout"
import hero from "../../../../components/hero/Hero"
import cards from "../../../../components/cards/Cards"
import textimage from "../../../../components/textImage/TextImage"
import texteditor from "../../../../components/textEditor/TextEditor"
import posts from "../../../../components/posts/Posts"
import postcards from "../../../../components/postcards/Postcards"
import quote from "../../../../components/quote/Quote"
import angledimage from "../../../../components/angledImage/AngledImage"
import contactus from "../../../../components/contactUs/ContactUs"
import form from "../../../../components/form/Form"
import cta from "../../../../components/cta/Cta"
import downloads from "../../../../components/downloads/Downloads"
import reviews from "../../../../components/reviews/Reviews"
import content from "../../../../components/content/Content"
import resources from "../../../../components/resources/Resources"

const blocks = {
  hero,
  cards,
  textimage,
  texteditor,
  posts,
  postcards,
  quote,
  angledimage,
  contactus,
  form,
  cta,
  downloads,
  reviews,
  content,
  resources
}

const Template = (props) => {
  const {
    pageContext: { themeOptions },
    data: {
      wpPage: {
        template: { acf },
        seo
      }
    }
  } = props

  return (
    <Layout {...props} seo={seo}>
      {acf?.blocks?.flex &&
        acf.blocks.flex.map(({ id: fieldId, fieldGroupName, ...rest }, index) => {
          const id = fieldId || fieldGroupName?.split("_").pop().toLowerCase()

          const Component = blocks?.[id]
          return Component && <Component key={index} {...rest} themeOptions={themeOptions} />
        })}
    </Layout>
  )
}

export const Query = graphql`
  query PageDefault($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      databaseId
      title
      template {
        templateName
      }
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
      }
      template {
        ... on WpDefaultTemplate {
          templateName
          acf {
            blocks {
              flex {
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Hero {
                  fieldGroupName
                  bgcolor
                  buttons {
                    button {
                      target
                      title
                      url
                    }
                    variant
                  }
                  headline
                  alignment
                  image {
                    id
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                      }
                    }
                  }
                  subline
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Content {
                  fieldGroupName
                  headline
                  image {
                    id
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                      }
                    }
                  }
                  subline
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Cards {
                  columns
                  introduction
                  fieldGroupName
                  items {
                    headline
                    fieldGroupName
                    text
                    link {
                      target
                      title
                      url
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Quote {
                  fieldGroupName
                  text
                  headline
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Resources {
                  fieldGroupName
                  items {
                    headline
                    buttons {
                      button {
                        target
                        title
                        url
                      }
                      variant
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Downloads {
                  fieldGroupName
                  items {
                    headline
                    text
                    category
                    date
                    link {
                      target
                      title
                      url
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Form {
                  fieldGroupName
                  formid
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Cta {
                  fieldGroupName
                  headline
                  text
                  flex {
                    ... on WpDefaultTemplate_Acf_Blocks_Flex_Cta_Flex_Form {
                      fieldGroupName
                      formid
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Reviews {
                  fieldGroupName
                  items {
                    fieldGroupName
                    text
                    person
                    position
                    star
                  }
                  buttons {
                    button {
                      target
                      title
                      url
                    }
                    variant
                  }
                }

                ... on WpDefaultTemplate_Acf_Blocks_Flex_Postcards {
                  fieldGroupName
                  bgcolor
                  items {
                    headline
                    text
                    buttons {
                      button {
                        target
                        title
                        url
                      }
                      variant
                    }
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                        }
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Angledimage {
                  fieldGroupName
                  headline
                  text
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                      }
                    }
                  }
                  buttons {
                    button {
                      target
                      title
                      url
                    }
                    variant
                  }
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Contactus {
                  fieldGroupName
                  headline
                  text
                  phone
                  address
                  email
                  subline
                  link {
                    target
                    title
                    url
                  }
                  flex {
                    ... on WpDefaultTemplate_Acf_Blocks_Flex_Contactus_Flex_Form {
                      fieldGroupName
                      formid
                    }
                  }
                }

                ... on WpDefaultTemplate_Acf_Blocks_Flex_Textimage {
                  fieldGroupName
                  alignment
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                      }
                    }
                  }
                  text
                  headline
                  buttons {
                    button {
                      target
                      title
                      url
                    }
                    variant
                  }
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Texteditor {
                  fieldGroupName
                  flex {
                    ... on WpDefaultTemplate_Acf_Blocks_Flex_Texteditor_Flex_Text {
                      fieldGroupName
                      text
                    }
                    ... on WpDefaultTemplate_Acf_Blocks_Flex_Texteditor_Flex_Textimage {
                      alignment
                      fieldGroupName
                      image {
                        altText
                        localFile {
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                          }
                        }
                      }
                      text
                    }
                    ... on WpDefaultTemplate_Acf_Blocks_Flex_Texteditor_Flex_Gallery {
                      columns
                      fieldGroupName
                      gallery {
                        altText
                        localFile {
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                          }
                        }
                      }
                    }
                    ... on WpDefaultTemplate_Acf_Blocks_Flex_Texteditor_Flex_Embed {
                      fieldGroupName
                      url
                    }
                    ... on WpDefaultTemplate_Acf_Blocks_Flex_Texteditor_Flex_Quote {
                      author
                      fieldGroupName
                      position
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Template
