const config = {
  id: "content",
  label: "Content",
  fields: [
    {
      id: "image",
      type: "image",
      label: "Image"
    },
    {
      id: "headline",
      type: "text",
      label: "Headline",
      defaultValue: "Activated charcoal mustache typewriter copper mug"
    },
    {
      id: "subline",
      type: "wysiwyg",
      label: "Subline",
      defaultValue:
        "Viral pour-over ugh narwhal flexitarian raclette woke wayfarers direct trade godard yr kogi gentrify authentic"
    }
  ]
}

export default config
