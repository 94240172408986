import buttonFields from "../button/config"

const config = {
  id: "textimage",
  label: "Text & Image",
  fields: [
    {
      id: "image",
      type: "image",
      label: "Add Image"
    },

    {
      id: "text",
      type: "wysiwyg",
      label: "Text"
    },
    {
      id: "headline",
      type: "text",
      label: "Headline"
    },
    {
      id: "buttons",
      type: "repeater",
      label: "Button",
      items: buttonFields
    },
    {
      id: "alignment",
      type: "select",
      label: "Image Alignment",
      defaultValue: "left",
      options: [
        {
          name: "Left",
          value: "left"
        },
        {
          name: "Right",
          value: "right"
        }
      ]
    }
  ]
}

export default config
