const config = {
  id: "downloads",
  label: "Downloads",
  fields: [
    {
      id: "items",
      type: "repeater",
      label: "Items",
      items: [
        {
          id: "headline",
          type: "text",
          label: "Headline"
        },
        {
          id: "text",
          type: "text",
          label: "Text",
          rows: 2
        },
        {
          id: "link",
          type: "link",
          label: "Link"
        },
        {
          id: "category",
          type: "text",
          label: "Category Title",
          defaultValue: "Category"
        },
        {
          id: "date",
          type: "date_picker",
          label: "Date"
        }
      ]
    }
  ]
}

export default config
