const config = {
  id: "footer",
  label: "Footer",
  type: "group",
  hide: true,
  fields: [
    {
      id: "image",
      type: "image",
      label: "Logo"
    },

    {
      id: "text",
      type: "wysiwyg",
      label: "Description",
      defaultValue: "© 2020 Tailblocks — @knyttneve"
    },

    {
      id: "facebook",
      type: "text",
      label: "Facebook",
      defaultValue: "https://github.com/robinzimmer1989/jam-cms"
    },
    {
      id: "instagram",
      type: "text",
      label: "Instagram",
      defaultValue: "https://github.com/robinzimmer1989/jam-cms"
    },
    {
      id: "twitter",
      type: "text",
      label: "Twitter",
      defaultValue: "https://github.com/robinzimmer1989/jam-cms"
    },
    {
      id: "linkedin",
      type: "text",
      label: "LinkedIn",
      defaultValue: "https://github.com/robinzimmer1989/jam-cms"
    }
  ]
}

export default config
