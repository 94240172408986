module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-jam-cms/gatsby-browser.js'),
      options: {"plugins":[],"source":"https://fournierdev.wpengine.com","apiKey":"7c4de1a4-c8aa-4ed7-be71-8927e39cf869","fields":"/opt/build/repo/src/fields","settings":{"postsPerPage":2}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://fournierdev.wpengine.com/graphql","develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"787717155225755"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
