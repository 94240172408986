import buttonFields from "../button/config"

const config = {
  id: "angledimage",
  label: "Angled Image",
  fields: [
    {
      id: "image",
      type: "image",
      label: "Add Image",
    },
    {
      id: "headline",
      type: "text",
      label: "Headline",
      defaultValue: "Activated charcoal mustache typewriter copper mug",
    },
    {
      id: "text",
      type: "text",
      label: "Text",
      defaultValue:
        "Viral pour-over ugh narwhal flexitarian raclette woke wayfarers direct trade godard yr kogi gentrify authentic",
    },
    {
      id: "buttons",
      type: "repeater",
      label: "Button",
      items: buttonFields,
    },
  ],
}

export default config
