import buttonFields from "../button/config"

const config = {
  id: "header",
  label: "Header",
  type: "group",
  hide: true,
  fields: [
    {
      id: "image",
      type: "image",
      label: "Image logo"
    },
    {
      id: "buttons",
      type: "repeater",
      label: "Button",
      items: buttonFields
    },
    {
      id: "menu",
      type: "menu",
      label: "Menu"
    }
  ]
}

export default config
