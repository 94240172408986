import React from "react"
import { RichText } from "jam-cms"
import Parser from "html-react-parser"

// import app components
import Button from "../button/Button"
import Form from "../form/Form"

const ContactUs = (props) => {
  const { flex, email, address, phone, headline, text, subline, link } = props

  return (
    <section className="text-gray-600 body-font relative pb-16">
      <div className="container px-5 pt-10 mx-auto flex sm:flex-nowrap flex-wrap">
        <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
          {link && (
            <iframe
              width="100%"
              height="100%"
              className="absolute inset-0"
              frameborder="0"
              title="map"
              marginheight="0"
              marginwidth="0"
              scrolling="no"
              src={link.url}
              style={{ filter: "grayscale(1) contrast(1.2) opacity(0.4)" }}
            ></iframe>
          )}
          <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md w-full">
            <div className="lg:w-1/2  px-2 md:px-6 mt-4  lg:mt-0 break-all">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs ">ADDRESS</h2>
              <p className="mt-1 text-xs md:text-base">{address && Parser(address)}</p>
            </div>
            <div className="lg:w-1/2 px-2 md:px-6 mt-4 lg:mt-0 break-all">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
              <a href={`mailto:${email}`} className="text-primary leading-relaxed text-xs md:text-base ">
                {email && Parser(email)}
              </a>
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
              <a href={`tel:${phone}`} className="text-primary leading-relaxed text-xs md:text-base">
                {phone && Parser(phone)}
              </a>
            </div>
          </div>
        </div>

        <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
          {headline && <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">{Parser(headline)}</h2>}
          {text && <p className="leading-relaxed mb-5 text-gray-600">{Parser(text)}</p>}
          {console.log("flex", flex)}
          {flex && <div className="relative mb-4">{<Form {...flex[0]} hasStyle={true} />}</div>}

          {subline && <p className="text-xs text-gray-500 mt-3">{Parser(subline)} </p>}
        </div>
      </div>
    </section>
  )
}

export default ContactUs
