import React from "react"
import { RichText } from "jam-cms"
import Parser from "html-react-parser"

// import app components
import Button from "../button/Button"
import Form from "../form/Form"

const Cta = (props) => {
  const { flex, headline, text } = props

  return (
    <section className="text-gray-600 body-font pb-16">
      <div className="container px-5 pt-10 mx-auto">
        <div className="flex flex-col text-center w-full mb-12 justify-center">
          {headline && (
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">{Parser(headline)}</h1>
          )}
          {text && <p className="lg:w-2/3 mx-auto leading-relaxed text-base">{Parser(text)}</p>}
        </div>
        <div className="flex lg:w-2/3 w-full sm:flex-row flex-col mx-auto px-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end justify-center">
          {flex && <Form {...flex[0]} hasStyle={true} />}
        </div>
      </div>
    </section>
  )
}

export default Cta
