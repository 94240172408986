const config = {
	id: "quote",
	label: "Quote",
	fields: [
		{
			id: "headline",
			type: "text",
			label: "Headline",
			defaultValue: "A quote headline",
		},
		{
			id: "text",
			type: "text",
			label: "Text",
			defaultValue:
				"Viral pour-over ugh narwhal flexitarian raclette woke wayfarers direct trade godard yr kogi gentrify authentic",
		},
	],
};

export default config;
